<template>
  <div class="grid grid-cols-5 gap-2 text-gray-600 mb-4">

      <template v-if="client.appointment.date">
        <div class="font-medium col-start-1 col-span-2">Termin am:</div>
        <div class="font-semibold col-span-3">{{ client.appointment.date }}</div>
      </template>

      <template v-if="client.appointment.time">
        <div class="font-medium col-start-1 col-span-2">Termin um:</div>
        <div class="font-semibold col-span-3">{{ client.appointment.time }} Uhr</div>
      </template>

      <div class="font-medium col-start-1 col-span-2">Standort:</div>
      <div class="font-semibold col-span-3">{{ client.appointment.location.name }}</div>

      <template v-if="client.action !== 'setTestResultDone'">
        <div class="font-medium col-start-1 col-span-2">Status:</div>
        <div class="font-semibold col-span-3">
          {{ client.appointment.status.label }}
        </div>
      </template>

      <div class="font-medium col-start-1 col-span-2">Name:</div>
      <div class="font-semibold col-span-3">
        {{ client.person.first_name }} {{ client.person.last_name }}
      </div>

      <div class="font-medium col-start-1 col-span-2">Geburtsdatum:</div>
      <div class="font-semibold col-span-3">{{ client.person.date_of_birth }}</div>

      <template v-if="client.person.address">
        <div class="font-medium col-start-1 col-span-2">Adresse:</div>
        <div class="font-semibold col-span-3" v-html="client.person.address"></div>
      </template>

      <template v-if="client.appointment.service">
        <template v-if="client.appointment.service.payment && client.appointment.service.payment.status && client.appointment.service.payment.status.label">
          <div class="font-medium col-start-1 col-span-2">Bezahlstatus:</div>
          <div class="font-semibold col-span-3">{{ client.appointment.service.payment.status.label }}</div>
        </template>

        <div class="font-medium col-start-1 col-span-2">Gebuchte Dienstleistung:</div>
        <div class="font-semibold col-span-3">{{ client.appointment.service.name }}</div>
      </template>

      <template v-if="client.appointment.location.services">
        <div class="font-bold col-start-1 col-span-5 text-red-500"
             v-if="client.appointment.location.services.length === 0">
          An diesem Standort wird keine passende Dienstleistung angeboten.
        </div>
        <template v-else>
          <div class="font-bold col-start-1 col-span-5 text-red-500"
               v-if="client.appointment.location.services.length > 1 && client.appointment.service.uuid !== selectedServiceUuid">
            Bitte überprüfen Sie, welche der an diesem Standort angebotenen Leistungen mit der gebuchten Dienstleistung vergleichbar ist.
          </div>
          <div class="font-medium col-start-1 col-span-2">Am Standort angebotene Dienstleistungen:</div>
          <select class="font-semibold col-span-3" v-model="selectedServiceUuid" v-on:change="changeService()">
            <option
              v-for="service in client.appointment.location.services"
              v-bind:key="service.uuid"
              v-bind:value="service.uuid">{{service.name}}</option>
          </select>
        </template>
      </template>

      <div v-if="client.appointment.link" class="col-start-1 col-span-5">
        <a class="group" v-bind:href="client.appointment.link" target="_blank">↗ <span class="underline group-hover:no-underline">Detailseite des Termins</span></a>
      </div>

  </div>
</template>

<script>
export default {
  name: 'Client',
  props: {
    client: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      selectedServiceUuid: null,
    }
  },
  created() {
      // preselect service dropdown, if there is at least one available service
      if (this.client.appointment.location.services && this.client.appointment.location.services.length > 0) {
        // try to find the matching uuid, fallback to first item
        let searchService = this.client.appointment.location.services.find((s) => {
          return s.uuid === this.client.appointment.service.uuid;
        }) || this.client.appointment.location.services[0];

        this.selectedServiceUuid = searchService.uuid;
        this.changeService();
      }
  },
  methods: {
    changeService() {
      this.$emit('changeService', this.client.appointment.location.services.find((s) => {
        return s.uuid === this.selectedServiceUuid;
      }));
    }
  }
}
</script>