<template>
  <div id="app">
    <t-dialog
      name="check-in"
      icon=null
      type="confirm"
      :ok-button-text="client.appointment.additional_data_collection === false ? 'Einchecken' : 'Dateneingabe'"
      cancel-button-text="Abbrechen"
    >
      <template v-slot:title>
        <h2 class="mb-6 text-xl font-bold text-center">
          Person einchecken?
        </h2>
      </template>

      <client :client="client"
              v-on:changeService="changeService($event)"></client>
    </t-dialog>

    <t-dialog
      name="additional-data-before-check-in"
      icon=null
      type="confirm"
      variant="fullWidth"
      ok-button-text="Einchecken"
      cancel-button-text="Abbrechen"
    >
      <template v-slot:title>
        <h2 class="mb-6 text-xl font-bold text-center">
          Dateneingabe
        </h2>
      </template>

      <p v-if="clientValidationErrors.message"
         v-text="clientValidationErrors.message"
         class="mb-4 p-4 bg-red-100 text-center"></p>

      <ul class="space-y-6">
        <li v-for="(config, i) in client.appointment.additional_data_collection" :key="i">
          <data-collection-item
            :config="config"
            :validation="clientValidationErrors.data[config.name]"
            v-model="clientValues.additional_data_collection[config.name]"></data-collection-item>
        </li>
      </ul>
    </t-dialog>

    <t-dialog
      name="check-in-status"
      :icon="appStatus === 'cancelled' ? 'error' : 'success'"
      type="alert"
      ok-button-text="Schließen"
    >
      <template slot="title">
        <h2 class="mb-6 text-xl font-bold text-center">
          <template v-if="appStatus === 'cancelled'">
            Der Termin wurde storniert.
          </template>
          <template v-else>
            Person ist eingecheckt!
          </template>
        </h2>
      </template>

      <client :client="client"></client>
    </t-dialog>

    <t-dialog
      name="test-result"
      :icon="appStatus === 'setTestResultDone' ? 'success' : null"
      type="alert"
      :variant="appStatus === 'setTestResultDone' ? null : 'silentAlert'"
      :ok-button-text="appStatus === 'setTestResultDone' ? 'Ok' : 'Abbrechen'"
    >
      <template slot="title">
        <h2 class="mb-6 text-xl font-bold text-center">
          <template v-if="appStatus === 'setTestResultDone'">
            <span v-if="clientPersonHasResult">Ergebnis wurde gesetzt!</span>
            <span v-else>Ergebnis wurde bereits gespeichert.</span>
          </template>
          <template v-else>
            Ergebnis des Tests eintragen
          </template>
        </h2>
      </template>

      <client :client="client"></client>

      <template v-if="appStatus !== 'setTestResultDone'">
        <div class="flex justify-between">
          <div class="w-1/2 p-4 bg-green-100">
            <t-button variant="success" class="mx-auto text-2xl"
                      @click="sendTestResult('negative')"
            >Negativ</t-button>
          </div>

          <div class="w-1/2 p-4 bg-red-100">
            <t-button variant="error" class="mx-auto text-2xl"
                      @click="sendTestResult('positive')"
            >Positiv</t-button>
          </div>
        </div>

        <div class="p-4 bg-yellow-50">
          <t-button variant="alert" class="mx-auto text-xl"
                    @click="sendTestResult('failed')"
          >Ohne Ergebnis</t-button>
        </div>

        <p class="mt-4" v-if="isAuthenticated && isLocated && isSigned">
          Das Testergebnis wird unterzeichnet von <strong>{{user.first_name}} {{user.last_name}}</strong>
          für den Standort <strong>{{user.selectedLocation.label}}</strong>.
        </p>
      </template>

      <template v-else>
        <div v-if="client.person.result === 'negative'"
             class="w-full p-4 text-3xl text-center text-green-900 bg-green-100">
          Negativ
        </div>

        <div v-if="client.person.result === 'positive'"
             class="w-full p-4 text-3xl text-center text-red-900 bg-red-100">
          Positiv
        </div>

        <div v-if="client.person.result === 'failed'"
             class="w-full p-4 text-3xl text-center text-yellow-900 bg-yellow-100">
          Ohne Ergebnis
        </div>

      </template>

    </t-dialog>

    <nav v-bind:class="{'absolute': appStatus !== 'missingAuthentication' && appStatus !== 'missingSignature'}"
         class="z-10 flex justify-between w-full p-4 bg-white bg-opacity-50">
      <a href="#" @click.prevent="resetScanner()"><img src="img/app-logo.svg" class="w-auto h-10" alt="Scanner"></a>
      <div>
        <t-dropdown v-if="isAuthenticated && isLocated && isSigned"
                    :text="(user.first_name.charAt(0) + user.last_name.charAt(0)).toUpperCase()"
        >
          <div slot-scope="{ hide }" class="py-1 space-y-4 rounded-md shadow-xs">
            <div
              class="block px-4 py-4 -my-1 leading-5 text-gray-700 bg-gray-100"
            >
              {{user.first_name}} {{user.last_name}}<br>
              <span class="text-sm">{{user.selectedLocation.label}}</span>
              <template v-if="user.selectedTerminal && user.selectedTerminal.label">
                <br>
                <span class="text-sm">{{user.selectedTerminal.label}}</span>
              </template>
            </div>
            <button
              @click.prevent="resetLocation()"
              class="block w-full px-4 py-2 leading-5 text-red-700 transition duration-150 ease-in-out hover:bg-red-500 hover:text-white focus:outline-none"
              role="menuitem"
            >
              Standort<template v-if="user.selectedTerminal && user.selectedTerminal.label"> / Terminal</template> wechseln
            </button>
            <button
              @click.prevent="hide(); resetSignature();"
              class="block w-full px-4 py-2 leading-5 text-red-700 transition duration-150 ease-in-out hover:bg-red-500 hover:text-white focus:outline-none"
              role="menuitem"
            >
              Unterschrift ändern
            </button>
            <button
              @click.prevent="logout()"
              class="block w-full px-4 py-2 leading-5 text-red-700 transition duration-150 ease-in-out hover:bg-red-500 hover:text-white focus:outline-none"
              role="menuitem"
            >
              Logout
            </button>
          </div>
        </t-dropdown>
      </div>
    </nav>
    <main>
      <template v-if="appStatus === 'missingAuthentication'">
        <login :apiBaseUrl="apiBaseUrl" v-model="user"></login>
      </template>

      <template v-else-if="appStatus === 'missingSignature'">
        <signature :apiBaseUrl="apiBaseUrl" :user="user" v-model="user"></signature>
      </template>

      <template v-else>
        <div class="w-screen h-screen">
          <qrcode-stream :camera="camera" @decode="onDecode" @init="onInit" @unhandledRejection="resetScanner" />
        </div>
      </template>
    </main>
  </div>
</template>

<script>
import { QrcodeStream } from 'vue-qrcode-reader';
import Client from '@/components/Client';
import Login from '@/components/Login';
import Signature from "@/components/Signature";
import DataCollectionItem from "@/components/DataCollectionItem";

export default {
  name: 'App',
  components: {
    QrcodeStream,
    Client,
    Login,
    Signature,
    DataCollectionItem,
  },

  data() {
    return {
      camera: 'auto', //
      decodedUrl: '', // from qr code
      error: '',      // from qr code stream reader
      apiBaseUrl: null,

      user: {
        first_name: null,
        last_name: null,
        has_signature: false,
        locations: [],
        uuid: null,
        token: null,
        selectedLocation: {
          value: null,
          label: null,
          terminals: [],
        },
        selectedTerminal: {
          value: null,
          label: null
        }
      },

      client: {
        bookingId: null,
        personId: null,
        appointment: {
          additional_data_collection: false,
          date: null,
          time: null,
          status: {
            label: null,
            value: null
          },
          location: {
            uuid: null,
            name: null,
            // available services at the location (walk-in)
            services: [
              // {
              //   uuid: null,
              //   name: null,
              //   group: null
              // }
            ],
          },
          service: {
            uuid: null,
            name: null, // e.g. "Kostenloser Corona-Schnelltest",
            group: null, // e.g. "poc"
            payment: {
              status: {
                label: null, // e.g. "Bezahlt"
                value: null, // e.g. "paid"
              }
            }
          },
          link: null, // url to booking item (open in new tab)
        },
        person: {
          first_name: null,
          last_name: null,
          date_of_birth: null,
          address: null,
          result: null,
        },
        action: null,
      },

      clientValues: {
        service: { // selected service (walk-in)
          uuid: null,
          name: null,
          group: null,
        },
        additional_data_collection: {}, // additional client data
      },

      clientValidationErrors: {
        message: null,
        data: {},
      }
    };
  },

  created() {
    // duplicate empty client fields to a
    // separate property, which allows
    // us to safely reset a client
    this.emptyClient = this.client;

    this.apiBaseUrl = window.location.origin.includes('://scanner') ? window.location.origin : 'https://api.staging.apotermin.online';
    this.apiBaseUrl = this.apiBaseUrl.replace('://scanner.', '://api.');
    this.apiBaseUrl = this.apiBaseUrl.replace('://scanner-', '://api-');

    // this.apiBaseUrl = window.location.origin.includes('://scanner')
    //   ? window.location.origin.replace('://scanner.', '://api')
    //   : 'https://api.staging.covidservicepoint.de';

  },

  computed: {
    isAuthenticated() {
      return typeof this.user.token === 'string';
    },
    isLocated() {
      return typeof this.user.selectedLocation === 'object'
        && this.user.selectedLocation !== null
        && typeof this.user.selectedLocation.value === 'string';
    },
    isAssignedToTerminal() {
      return this.isLocated &&
        (this.user.selectedLocation.terminals.length <= 1
          ||
        this.user.selectedLocation.terminals.length > 1
        && typeof this.user.selectedTerminal === 'object'
        && this.user.selectedTerminal !== null
        && typeof this.user.selectedTerminal.value === 'string');
    },
    isSigned() {
      return typeof this.user.has_signature !== 'undefined'
        && this.user.has_signature === true;
    },

    clientPersonHasResult() {
      return typeof this.client.person.result !== 'undefined'
        && this.client.person.result;
    },


    isWalkInCode() {
      // decodedUrl contains 'booking-walk-in-item'
      return this.decodedUrl && this.decodedUrl.includes('/booking-walk-in-item/')
    },

    appStatus() {


      // user is not logged in
      if (! this.isAuthenticated) {
        return 'missingAuthentication';
      }

      // user's signature is missing
      if (! this.isSigned) {
        return 'missingSignature';
      }

      // user has not selected a location
      if (! this.isLocated) {
        return 'missingLocation';
      }

      // selected location has more than one terminal
      if (! this.isAssignedToTerminal) {
        return 'missingTerminal';
      }

      if (this.client.action) {
        switch (this.client.action) {

          case 'walkIn':
            return 'notCheckedIn';

          case 'checkIn' :
            if (this.client.appointment.status.value === 'notCheckedIn') {
              return 'notCheckedIn';
            } else if (this.client.appointment.status.value === 'checkedIn') {
              return 'checkedIn';
            }
            return 'unknown';

          case 'setTestResultDone' :
            return 'setTestResultDone';

          default:
            return 'awaitingTestResult';
        }
      }

      return 'idle';
    }
  },

  watch: {

    decodedUrl(value) {
      // the watcher only "fires" when value has been changed
      if (value !== null) {

        if (this.isWalkInCode) {
          window.axios.post(
            value,
            { location: this.user.selectedLocation.value },
            { headers: {'Authorization': 'Bearer ' + this.user.token} }
          ).then(response => {
              // set client from response
              this.client = response.data;
              // reset client values
              this.resetClientValues();
            });
        }

        else {
          // default get request to load appointment
          window.axios.get(value, { headers: {'Authorization': 'Bearer ' + this.user.token} })
            .then(response => {
              if (response.data.appointment.location.uuid !== this.user.selectedLocation.value) {
                this.$dialog.alert({
                  title: 'Falscher Standort',
                  text: 'Der Termin wurde für den Standort "'+ response.data.appointment.location.name +'" gebucht, Sie sind jedoch aktuell am Standort "' + this.user.selectedLocation.label + '" angemeldet.',
                  icon: 'error',
                  clickToClose: false,
                  escToClose: false,
                  okButtonText: 'Ok',
                }).then(result => {
                  if (result.isOk) {
                    // reset scanner to allow re-scan
                    this.resetScanner();
                  }
                });
              }
              else {
                // set client from response
                this.client = response.data;
                // reset client values
                this.resetClientValues();
              }
            });
        }



      }

    },
    error(value) {
      if (value !== null) {
        this.$dialog.alert({
          title: 'Fehler',
          text: this.error,
          icon: 'error',
          clickToClose: false,
          escToClose: false,
          okButtonText: 'Ok',
        }).then((result) => {
          if (result.isOk) {
            // restart of whole app
            window.location.reload();
          }
        });
      }
    },
    appStatus(value) {
      switch (value) {

        // location not selected
        case 'missingLocation' :
          this.selectLocation();
          break;

        // terminal not selected
        case 'missingTerminal' :
          this.selectTerminal();
          break;

        // not checked in
        case 'notCheckedIn' :
          this.$dialog.show('check-in').then((result) => {
            if (result.isOk) {
              // just perform the check in if no further data is required
              if (this.client.appointment.additional_data_collection === false) {
                this.sendCheckIn();
              }
              // ask for additional data before checkin
              else {
                this.$dialog.show('additional-data-before-check-in').then(result => {
                  if (result.isOk) {
                    this.sendCheckIn();
                  }
                  else {
                    this.resetScanner();
                  }
                });
              }
            }
            else {
              // reset scanner when check in was cancelled
              this.resetScanner();
            }
          });
          break;

        // already checked in or cancelled
        case 'cancelled' :
        case 'checkedIn' :
          this.$dialog.show('check-in-status')
            .then(() => {
              // reset app after already checked in message has been closed
              this.resetScanner();
            });
          break;

        // checked in and waiting for test results
        case 'awaitingTestResult' :
          this.$dialog.show('test-result').then(() => {
            this.resetScanner();
          });
          break;

        case 'setTestResultDone' :
          this.$dialog.show('test-result').then(() => {
            this.resetScanner();
          });
          break;

        default:
          break;
      }
    }
  },

  methods: {

    sendCheckIn() {
      // check in client
      window.axios.put(this.decodedUrl, {
        location: this.user.selectedLocation.value,
        terminal: this.user.selectedTerminal ? this.user.selectedTerminal.value || null : null,
        service: this.isWalkInCode && this.clientValues.service ? this.clientValues.service.uuid || null : null, // walk-in
        additional_data_collection: this.clientValues.additional_data_collection,
      }, { headers: {'Authorization': 'Bearer ' + this.user.token} })
        .then(response => {
          this.client = response.data;
          this.$dialog.show('check-in-status')
            .then(() => {
              // reset scanner after check in success message has been closed
              this.resetScanner();
            });
        })
        .catch(error => {
          // handle 422 error response
          // when we just have asked for additional_data_collection,
          // otherwise fallback to general error response
          if (error.response.status === 422 && this.client.appointment.additional_data_collection !== false) {
            this.clientValidationErrors.message = 'Bitte korrigieren Sie die markierten Felder.';
            this.clientValidationErrors.data = error.response.data;

            // open dialog again
            this.$dialog.show('additional-data-before-check-in').then(result => {
              if (result.isOk) {
                this.sendCheckIn();
              }
              else {
                this.resetScanner();
              }
            });
          }
          // general error response
          else {
            this.error = 'Beim Checkin trat ein technischer Fehler auf. Bitte probieren Sie es erneut.';
          }
        });
    },

    resetClientValues() {
      this.clientValues = {
        service: { // selected service (walk-in)
          uuid: null,
          name: null,
          group: null,
        },
        additional_data_collection: {}, // additional client data
      };

      this.clientValidationErrors = {
        message: null,
        data: {},
      };
    },

    logout() {
      window.axios.post(this.apiBaseUrl + '/auth/logout', {}, { headers: {'Authorization': 'Bearer ' + this.user.token} })
      .then(() => {
        this.user = {
          email: null,
          name: null,
          token: null,
          locations: [],
          selectedLocation: {
            value: null,
            label: null,
          }
        };
      })
      .catch(() => {
        this.user = {
          email: null,
          name: null,
          token: null,
          locations: [],
          selectedLocation: {
            value: null,
            label: null,
          }
        };
      });

    },

    resetLocation() {
      this.user.selectedLocation = null;
      this.user.selectedTerminal = null;
    },

    resetSignature() {
      this.$dialog.confirm({
        title: 'Unterschrift ändern',
        text: 'Hier können Sie Ihre bereits hinterlegte Unterschrift löschen. Anschließend werden Sie direkt aufgefordert, eine neue Unterschrift zu hinterlegen.',
        icon: 'question',
        clickToClose: false,
        escToClose: false,
        cancelButtonText: 'Abbrechen',
        okButtonText: 'Unterschrift löschen',
      }).then(result => {
        if (result.isOk) {

          window.axios
            .delete(this.apiBaseUrl + '/auth/user/signature',{
              headers: {
                'Authorization': 'Bearer ' + this.user.token
              }
            })
            .then(() => {
              this.user.has_signature = false;
            });

        }
      });

      window.focus();

    },

    selectLocation() {
      this.$dialog.prompt({
        title: 'An welchem Standort befinden Sie sich?',
        text: 'Bitte wählen Sie den Standort aus...',
        icon: 'question',
        clickToClose: false,
        escToClose: false,
        cancelButtonText: 'Abbrechen',
        okButtonText: 'Auswählen',
        inputType: 'select',
        inputOptions: this.user.locations,
        inputPlaceholder: 'Standort'
      }).then((result) => {
        if (result.isOk) {
          this.resetScanner();

          this.$set(this.user, 'selectedLocation', {
            value: result.input,
            label: this.user.locations.find(location => location.value === result.input).label,
            terminals: this.user.locations.find(location => location.value === result.input).terminals || []
          });

          // if there is only one terminal available,
          // we can avoid the terminal prompt
          if (this.user.selectedLocation.terminals.length === 1) {
            this.$set(this.user, 'selectedTerminal', this.user.selectedLocation.terminals[0]);
          }
          else {
            this.$set(this.user, 'selectedTerminal', null);
          }

        }
        else {
          // we cannot continue without a location
          this.logout();
        }
      });
    },

    selectTerminal() {
      // console.log(this.user);
      this.$dialog.prompt({
        title: 'An Ihrem Standort "' + this.user.selectedLocation.label + '" gibt es mehrere Teststrecken bzw. Terminals',
        text: 'Bitte wählen Sie aus, an welchem Terminal Sie sich befinden...',
        icon: 'question',
        clickToClose: false,
        escToClose: false,
        cancelButtonText: 'Standort ändern',
        okButtonText: 'Auswählen',
        inputType: 'select',
        inputOptions: this.user.selectedLocation.terminals,
        inputPlaceholder: 'Teststrecke / Terminal'
      }).then((result) => {
        if (result.isOk) {
          this.resetScanner();

          this.$set(this.user, 'selectedTerminal', {
            value: result.input,
            label: this.user.selectedLocation.terminals.find(terminal => terminal.value === result.input).label
          });
        }
        else {
          // reset selected location
          // (terminal has not been selected yet)
          this.$set(this.user, 'selectedLocation', {
            value: null,
            label: null,
            terminals: []
          });
        }
      });
    },

    resetScanner(keepCamera) {
      // avoid calling window.location.reload()
      // because we do not want to lose the
      // current user token.
      this.client = this.emptyClient;
      this.decodedUrl = null;
      this.error = null;

      // When you hold a QR code in the camera, frames are actually decoded multiple times a
      // second but you don't want to be flooded with decode events that often. That is
      // why the last decoded QR code is always cached and only new results are
      // propagated. However changing the value of camera resets this internal cache.
      if (keepCamera !== true) {
        this.camera = 'off';
        setTimeout(() => {
          this.camera = 'auto';
        }, 10);
      }

    },

    changeService(service) {
      this.clientValues.service = service;
    },

    sendTestResult(result) {
      window.axios.post(this.decodedUrl, {
        result: result,
        location: this.user.selectedLocation.value,
        terminal: this.user.selectedTerminal ? this.user.selectedTerminal.value || null : null
      }, { headers: {'Authorization': 'Bearer ' + this.user.token} })
        .then(response => {
          this.client = response.data;
        });
    },

    onDecode(result) {
      this.decodedUrl = result;
    },

    async onInit(promise) {
      try {
        await promise;
      } catch (error) {

        console.log(error);

        if (error.name === 'NotAllowedError') {
          this.error = 'Haben Sie den Zugriff auf die Kamera zugelassen?'; // ERROR: you need to grant camera access permisson
        } else if (error.name === 'NotFoundError') {
          this.error = 'Es wurde keine Kamera erkannt.'; // ERROR: no camera on this device
        } else if (error.name === 'NotSupportedError') {
          this.error = 'Verbindungsfehler (SSL-Verschlüsselung).' // ERROR: secure context required (HTTPS, localhost);
        } else if (error.name === 'NotReadableError') {
          this.error = 'Ist die Kamera von einer anderen Anwendung bereits in Benutzung?'; // ERROR: is the camera already in use?
        } else if (error.name === 'OverconstrainedError') {
          this.error = 'Die erkannte Kamera ist nicht zum Scan von QR-Codes geeignet.'; // ERROR: installed cameras are not suitable
        } else if (error.name === 'StreamApiNotSupportedError') {
          this.error = 'Ihr Browser unterstützt kein dauerhaftes Einscannen über die Kamera.' // ERROR: Stream API is not supported in this browser;
        }
      }
    },
  },
};
</script>

<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
