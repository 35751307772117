<template>
  <div>
    <label v-if="config.label"
           v-text="config.label"
           class="block uppercase tracking-wide text-xs font-bold mb-1"
           :class="{'text-red-800': hasError}"
    ><span></span></label>

    <select v-if="config.type === 'select'"
            @change="change($event.target.value)"
            v-model="internalValue"
            class="block w-full px-3 py-2 text-black placeholder-gray-400 transition duration-100 ease-in-out bg-white border rounded shadow-sm focus:border-red-500 focus:ring-2 focus:ring-red-500 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed w-full"
            :class="{'border-red-700': hasError, 'border-gray-300': !hasError}">
      <option value="">Bitte wählen</option>
      <option v-for="(label, value) in config.options" :key="value" v-text="label" :value="value"></option>
    </select>

    <input v-else-if="config.type === 'input'"
           @input="change($event.target.value)"
           v-model="internalValue"
           class="block w-full px-3 py-2 text-black placeholder-gray-400 transition duration-100 ease-in-out bg-white border rounded shadow-sm focus:border-red-500 focus:ring-2 focus:ring-red-500 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed w-full"
           :class="{'border-red-700': hasError, 'border-gray-300': !hasError}"
           type="text">

    <textarea v-else-if="config.type === 'textarea'"
              @input="change($event.target.value)"
              v-model="internalValue"
              class="block w-full px-3 py-2 text-black placeholder-gray-400 transition duration-100 ease-in-out bg-white border rounded shadow-sm focus:border-red-500 focus:ring-2 focus:ring-red-500 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed w-full"
              :class="{'border-red-700': hasError, 'border-gray-300': !hasError}"></textarea>

    <div v-else-if="config.type === 'html'" v-html="config.content"></div>

    <div v-else-if="config.type === 'signature'" ref="signature" class="w-full relative">
      <canvas class="w-full h-full bg-yellow-100 border-b-8 border-red-700"></canvas>

      <div v-if="signaturePad" class="-mt-14 ml-2 z-100 absolute">
        <t-button @click="resetSignature()" variant="secondary">Unterschriftfeld leeren</t-button>
      </div>
    </div>
  </div>
</template>

<script>
import SignaturePad from "signature_pad";

export default {
  name: "DataCollectionItem",
  props: {
    config: {
      type: Object,
      required: true,
    },
    validation: {
      type: Array,
      // Object or array defaults must be returned from
      // a factory function
      default: function () {
        return [];
      }
    },
    value: {
      required: false,
    }
  },
  data() {
    return {
      signaturePad: null,
      hasError: false,
      internalValue: '',
    };
  },
  created() {
    this.internalValue = this.value || '';
    this.hasError = this.validation.length > 0;

    if (this.config.type === 'signature') {
      this.initSignaturePad();
      window.addEventListener('orientationchange', this.listenOnOrientationChange);
    }
  },

  destroyed() {
    if (this.config.type === 'signature') {
      window.removeEventListener('orientationchange', this.listenOnOrientationChange);
    }
  },

  methods: {
    change(value) {
      this.$emit('input', value);
      if (value === '' || value === null) {
        this.hasError = true;
      }
      else {
        this.hasError = false;
      }
    },
    listenOnOrientationChange() {
      setTimeout(() => {
        this.initSignaturePad();
      }, 100);
    },
    initSignaturePad() {
      this.$emit('input', null);

      this.$nextTick(() => {
        let signatureWrapper = this.$refs.signature;
        signatureWrapper.style.height = Math.round(signatureWrapper.offsetWidth / 5 * 2) + 'px';

        let canvas = signatureWrapper.querySelector('canvas');

        let ratio =  Math.max(window.devicePixelRatio || 1, 1);
        canvas.width = canvas.offsetWidth * ratio;
        canvas.height = canvas.offsetHeight * ratio;
        canvas.getContext("2d").scale(ratio, ratio);

        this.signaturePad = new SignaturePad(canvas, {
          penColor: 'rgb(20,70,120)'
        });

        this.signaturePad.addEventListener("endStroke", () => {
          this.$emit('input', this.signaturePad.toDataURL());
        }, { once: false });

        // populate signature field (e.g. another field caused validation error)
        if (this.internalValue && this.internalValue.startsWith('data:'))
        {
          this.signaturePad.fromDataURL(this.internalValue);
          this.$emit('input', this.internalValue);
        }
      });
    },
    resetSignature() {
      this.internalValue = null;

      // instead of just calling this.signaturePad.clear();
      // we re-initialize the whole signature pad
      this.initSignaturePad();
    }
  }
}
</script>