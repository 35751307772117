<template>
  <div class="flex justify-center items-center my-4">
    <div class="bg-white max-w-sm p-4 border-2 border-gray-50 shadow-lg rounded-lg">
      <h2 class="text-xl font-bold">Login</h2>
      <p class="mt-2 leading-snug">Bitte melden Sie sich mit Ihrer registrierten E-Mail-Adresse und Ihrem Passwort an.</p>

      <div class="text-left">
        <t-input-group
          :variant="errorMessage ? 'error' : null"
          label="Ihre registrierte E-Mail-Adresse"
          :feedback="errorMessage"
        >
          <t-input
            v-model="email"
            type="email"
          />
        </t-input-group>


        <t-input-group
          :variant="errorMessage ? 'error' : null"
          label="Ihr Passwort"
        >
          <t-input
            v-model="password"
            type="password"
          />
        </t-input-group>

        <div class="mt-6">
          <t-button class="w-full" @click="login()">Login</t-button>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "Login",

  props: {
    apiBaseUrl: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      email: null,
      password: null,

      errorMessage: null,
    }
  },

  methods: {
    login() {

      // reset former errors
      this.errorMessage = null;

      window.axios
        .post(this.apiBaseUrl + '/auth/login', {
          email: this.email,
          password: this.password
        })
        .then((response) => {
          if (response.status === 200) {
            // reset form input fields
            this.email = null;
            this.password = null;

            // get user from response
            let user = response.data.data.user;

            // add token to user object
            user.token = response.data.data.token;

            // map locations from [{uuid, name}] to [{value, label}]
            user.locations = user.locations.map(location => {
              return {
                value: location.uuid,
                label: location.name,
                terminals: (location.terminals || []).map(terminal => {
                  return {
                    value: terminal.uuid,
                    label: terminal.name
                  }
                }),
              }
            });

            // if there is only one location available,
            // we can avoid the location prompt
            if (user.locations.length === 1 && ! user.locations[0].disabled) {
              user.selectedLocation = user.locations[0];
            }

            this.$emit('input', user);

          }
          else {
            this.errorMessage = 'Es ist ein unerwarteter Fehler beim Login aufgetreten. Bitte versuchen Sie es erneut.';
          }

        })
        .catch((error) => {
          if (error.response.status === 422) {
            this.errorMessage = error.response.data.message;
          }
          else {
            this.errorMessage = 'Beim Login ist ein Fehler aufgetreten. Bitte probieren Sie es erneut.';
          }
        });
    }
  }
}
</script>

<style scoped>

</style>