<template>
  <div class="flex flex-col items-center justify-center px-6">
    <h2 class="text-xl font-bold text-center">Bitte hinterlegen Sie Ihre Unterschrift</h2>
    <p class="mt-2 max-w-xl">
      Testberichte werden mit Ihrer Unterschrift signiert.
      Diese wird dafür digital erfasst und automatisch in die Zertifikate eingefügt.
    </p>
    <div id="signature" class="mt-6 w-full">
      <canvas class="w-full h-full bg-yellow-100 border-b-8 border-red-700"></canvas>

      <div v-if="signaturePad" class="mt-4 pb-6 flex justify-between space-x-2">
        <t-button @click="resetSignature()" variant="secondary">Unterschriftfeld leeren</t-button>
        <t-button @click="storeSignature()" :disabled="signaturePad.isEmpty()">Unterschrift speichern</t-button>
      </div>
    </div>

  </div>
</template>

<script>
import SignaturePad from "signature_pad";

export default {
  name: 'Signature',

  props: {
    apiBaseUrl: {
      type: String,
      required: true
    },
    user: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      signaturePad: null,
    };
  },

  mounted() {
    this.initSignaturePad();

    window.addEventListener('orientationchange', () => {
      setTimeout(() => {
        this.initSignaturePad();
      }, 100);
    });
  },

  methods: {
    initSignaturePad() {
      this.$nextTick(() => {
        let signatureWrapper = document.getElementById('signature');

        signatureWrapper.style.height = Math.round(signatureWrapper.offsetWidth / 5 * 2) + 'px';

        let canvas = signatureWrapper.querySelector('canvas');

        let ratio =  Math.max(window.devicePixelRatio || 1, 1);
        canvas.width = canvas.offsetWidth * ratio;
        canvas.height = canvas.offsetHeight * ratio;
        canvas.getContext("2d").scale(ratio, ratio);

        this.signaturePad = new SignaturePad(canvas, {
          penColor: 'rgb(20,70,120)'
        });
      });
    },
    resetSignature() {
      // instead of just calling this.signaturePad.clear();
      // we re-initialize the whole signature pad
      this.initSignaturePad();
    },
    storeSignature() {
      let dataURL = this.signaturePad.toDataURL();

      window.axios
        .post(this.apiBaseUrl + '/auth/user/signature', {
            signature: dataURL
          },{
          headers: {
            'Authorization': 'Bearer ' + this.user.token
          }
        })
        .then(() => {
          let user = this.user;
          user.has_signature = true;

          this.$emit('input', user);
        });
    },
  }

}
</script>

<style scoped>

</style>